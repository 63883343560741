<template>
   <div class="main-wrapper">
    <div class="no-data-icon" >
      <img
          src="../../../assets/image/success.png"
          alt=""
          style="margin-bottom: 10px; width: 400px"
        />
        <span v-if="this.$route.query.id" class="no-data-font">编辑成功!</span>
         <span v-else class="no-data-font">创建成功!</span>
       
        <div style="padding-top: 30px;" @click="ret"><el-button>返回</el-button></div>
      </div>
   </div>
</template>

<script>


export default {
    data(){
        return{

        }
    },
    // mounted(){
    //     console.log(this.$route.query.id,'888')
    // },
    methods:{
        ret(){
            this.$router.push({
              path: "/liveStreaming/commodity",
            });
        }
    }
}
</script>



<style lang='scss' scoped>
.main-wrapper{
    height: 100%;
    .no-data-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10%;
        justify-content: center;
    }
    .no-data-font{
        font-weight: 600;
        font-size: 16px;
    }
}
</style>